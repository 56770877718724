<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>原点策略管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <div class="title">股指期货选择</div>
      <div class="search-content">
        <div style="display:flex;">
          <div style="display:flex;align-items:center;">
            <div style="width:100px;">股指期货</div>
            <el-select v-model="value" placeholder="请选择" style="width: 100%">
              <el-option v-for="item in options" :key="item.simplifiedName" :label="item.futureName" :value="item.simplifiedName">
              </el-option>
            </el-select>
          </div>
          <div class="search-btn" @click="getFutureAllDate">筛选</div>
        </div>
      </div>
    </el-card>
    <el-card shadow="always" class="list-container">
      <div class="header">
        <div class="header__title">原点策略设置</div>
      </div>
      <div class="content">
        <div class="chart">
          <div class="markPoint">
            <div class="markBlock">
              <div class="point continue"></div>
              <span>续点</span>
            </div>
            <div class="markBlock">
              <div class="point turn"></div>
              <span>拐点</span>
            </div>
          </div>
          <div v-if="options.length>0" id="chart"></div>
          <el-button @click="getFutureAllDate">刷新</el-button>
        </div>
        <div class="settingValue">
          <div class="module">
            <div class="point common">
              <div class="head">原点和均点</div>
              <div class="block" v-for="(item,index) in valueList" :key="index">
                <div class="title">{{item.name}}</div>
                <el-input :disabled='index === 2' @input="change(item.value,index)" title="" type="number" maxlength="7" v-model.trim="item.value"></el-input>
              </div>
            </div>
            <div class="xudian common">
              <div class="head">续点
                <span style="color:#0f40f5;cursor:pointer;marginLeft:20px" @click="addContinuePoints">+新增续点</span>
              </div>
              <div class="block" v-for="(item,index) in continuePoints" :key="index">
                <div style="width:56px">续点{{index +1}}</div>
                <div style="margin:0 20px">
                  <el-date-picker class="date_picker" :clearable='false' v-model="item.time" :disabled-date="setDisableDate" :default-time="new Date(2000, 1, 1, 9, 31, 0)" format='YYYY-MM-DD HH:mm' value-format="YYYY-MM-DD HH:mm" type="datetime" placeholder="选择日期时间" @change="changeXdianTime(item.time,index)">
                  </el-date-picker>
                </div>
                <div style="color:#ff0000;cursor:pointer" @click="delContinuePoints(index)">删除</div>
              </div>
            </div>
            <div class="guaidian common">
              <div class="head">拐点
                <span style="color:#0f40f5;cursor:pointer;marginLeft:20px" @click="addTurnPoints">+新增拐点</span>
              </div>
              <div class="block" v-for="(item,index) in turnPoints" :key="index">
                <div style="width:56px">拐点{{index +1}}</div>
                <div style="margin:0 20px">
                  <el-date-picker class="date_picker" :clearable='false' v-model="item.time" :disabled-date="setDisableDate" :default-time="new Date(2000, 1, 1, 9, 31, 0)" format='YYYY-MM-DD HH:mm' value-format="YYYY-MM-DD HH:mm" type="datetime" placeholder="选择日期时间" @change="changeInflectionPointTime(item.time,index)">
                  </el-date-picker>
                </div>
                <div style="color:#ff0000;cursor:pointer" @click="delTurnPoints(index)">删除</div>
              </div>
            </div>
          </div>
          <div class="sureBtn">
            <span @click="getSysRecommend">使用系统推荐</span>
            <el-button @click="srueEdit">确认修改</el-button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import * as echarts from 'echarts';
import { ElMessage } from "element-plus";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      options: [],
      value: '',
      valueList: [{ name: '多单原点', value: '' }, { name: '空单原点', value: '' }, { name: '均点', value: '' }],
      splitDate: '',
      continuePoints: [],
      newContinuePoints: [],
      turnPoints: [],
      newTurnPoints: [],
    })
    const setDisableDate = (time) => {
      let now = new Date();   //获取此时的时间
      let nowData = new Date(  //获取此时年月日的后一天
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1 //获取明天
      );
      let oneMonthAgo = new Date(  //获取一个月之前的时间
        now.getFullYear(),
        now.getMonth() - 1,  //获取上一个月
        now.getDate() + 1   //将多算的一天减掉
      );

      return (
        time.getTime() > nowData.getTime() - 1000  //可以选择到今天的xxx:xxx:xxx:23:59:59，只有的全部disabled
        || time.getTime() < oneMonthAgo.getTime()  //小于一个月的全部disabled掉
      );
    }
    const srueEdit = () => {
      let value0 = +state.valueList[0].value
      let value1 = +state.valueList[1].value
      // let status = state.valueList.every((item) => {
      //   return item.value && item.value !== '0'
      // })
      if (!value0 || !value1) {
        ElMessage({
          message: "请输入数值且数值不能为0",
          type: "warning",
        });
        return
      }
      // let value2 = +state.valueList[2].value
      // let value3 = +state.valueList[3].value
      // let value4 = +state.valueList[4].value
      // let value5 = +state.valueList[5].value
      if (+value1 <= +value0) {
        ElMessage({
          message: "空单原点应大于多单原点",
          type: "warning",
        });
        return
      }
      // if (+value2 <= +value4) {
      //   ElMessage({
      //     message: "空单原点下限应大于多单原点上限",
      //     type: "warning",
      //   });
      //   return
      // }
      // if (+value2 >= +value1) {
      //   ElMessage({
      //     message: "空单原点下限应小于空单原点上限",
      //     type: "warning",
      //   });
      //   return
      // }
      // if (!(+value0 < +value1 && +value0 > +value2)) {
      //   ElMessage({
      //     message: "空单原点值应在空单原点上限和下限之间",
      //     type: "warning",
      //   });
      //   return
      // }
      // if (+value5 >= +value4) {
      //   ElMessage({
      //     message: "多单原点下限应小于多单原点上限",
      //     type: "warning",
      //   });
      //   return
      // }
      // if (+value4 >= +value2) {
      //   ElMessage({
      //     message: "多单原点上限应小于空单原点下限",
      //     type: "warning",
      //   });
      //   return
      // }
      // if (!(+value3 < +value4 && +value3 > +value5)) {
      //   ElMessage({
      //     message: "多单原点值应在多单原点上限和下限之间",
      //     type: "warning",
      //   });
      //   return
      // }
      setPointData()
    }
    const change = (value, index) => {
      if (value >= 100000) {
        state.valueList[index].value = value.slice(0, 5)
      } else if (value.includes('.')) {
        state.valueList[index].value = parseFloat(value).toFixed(1)
      } else if (value <= 0) {
        state.valueList[index].value = null
      } else if (Number.isNaN(+value)) {
        ElMessage({
          message: '请输入数字',
          type: "success",
        });
        state.valueList[index].value = null
        return;
      }
      let value0 = +state.valueList[0].value
      let value1 = +state.valueList[1].value
      state.valueList[2].value = (value0 + value1) / 2
    }
    const getFutureTypeEnum = () => {
      proxy.$get('/cts/back/originStrategy/getFutureTypeEnum').then((res) => {
        if (res.resultState === '200') {
          state.options = res.data;
          state.value = res.data[0].simplifiedName
          getFutureAllDate()
        }
      }).catch((err) => {

      });
    }
    const getPointData = () => {
      let params = `?simplifiedName=${state.value}`
      proxy.$get('/cts/back/originStrategy/getPointData' + params).then((res) => {
        if (res.resultState === '200') {
          const { averageOrderMedium, longOrderMedium, shortOrderMedium, continuePoints, turnPoints } = res.data
          state.valueList[1].value = shortOrderMedium
          state.valueList[2].value = averageOrderMedium
          state.valueList[0].value = longOrderMedium
          if (continuePoints.length > 0) {
            state.continuePoints.length = 0
            continuePoints.forEach(item => {
              let newObj = {
                time: item,
                key: Date.now()
              }
              state.continuePoints.push(newObj)
              state.newContinuePoints = [...state.continuePoints];
            })
          } else {
            state.continuePoints = []
          }
          if (turnPoints.length > 0) {
            state.turnPoints.length = 0
            turnPoints.forEach(item => {
              let newObj = {
                time: item,
                key: Date.now()
              }
              state.turnPoints.push(newObj)
              state.newTurnPoints = [...state.turnPoints];
            })
          } else {
            state.turnPoints = []
          }


        }
      }).catch((err) => {

      });
    }
    // 获取系统推荐
    const getSysRecommend = () => {
      let params = `?simplifiedName=${state.value}`
      proxy.$get('/cts/back/originStrategy/useSystemRecommend' + params).then((res) => {
        if (res.resultState === '200') {
          getFutureAllDate()
        }
      }).catch((err) => {

      });
    }
    // 修改数据
    const setPointData = () => {
      state.continuePoints = state.continuePoints.filter(item => {
        return item.time
      });
      state.turnPoints = state.turnPoints.filter(item => {
        return item.time
      });
      let continuePoints = []
      state.continuePoints.forEach(item => {
        continuePoints.push(item.time)
      });
      let turnPoints = []
      state.turnPoints.forEach(item => {
        turnPoints.push(item.time)
      });
      let params = {
        averageOrderMedium: state.valueList[2].value,
        continuePoints: continuePoints,
        longOrderMedium: state.valueList[0].value,
        shortOrderMedium: state.valueList[1].value,
        simplifiedName: state.value,
        turnPoints: turnPoints
      }
      proxy.$post('/cts/back/originStrategy/setPointData', params).then((res) => {
        if (res.resultState === '200') {
          ElMessage({
            message: res.msg,
            type: "success",
          });
          getFutureAllDate()
        }
      }).catch((err) => {

      });
    }
    // 获取全量数据
    const getFutureAllDate = () => {
      let data = {
        simplifiedName: state.value,
        date: "5"
      }
      proxy.$post('/cts/back/originStrategy/getFutureAllDate', data).then((res) => {
        if (res.resultState === '200') {
          getPointData()
          let keyData = Object.keys(res.data.map)
          let xAxis = []
          let series1 = []
          let series2 = []
          let splitDate = []
          let allData = []
          keyData.forEach(item => {
            let data = res.data.map[item]
            data.forEach(item => {
              xAxis.push(item.dateTime)
              series1.push(item.price)
              series2.push(item.avgPrice)
              allData.push(item)
            });
            // splitDate.push(data[data.length - 1].dateTime.slice(0, 10))
            splitDate.push(data[data.length - 1].dateTime)
            xAxis.push(item)
            series1.push('')
            series2.push('')
          })
          let pieces = []
          let markPoint = []
          allData.forEach((item, index) => {
            let color = '#4297fe'
            let pointColor = '#4297fe'
            if (item.colour === '0') {
              color = '#4297fe'
            } else if (item.colour === '1') {
              // 绿色
              color = '#00ca6e'
            } else if (item.colour === '2') {
              // 红色
              color = '#ff675e'
            }
            if (item.continuationPointS) {
              // 续点 黄色
              pointColor = '#ffb900'
            } else if (item.turnPoint) {
              // 拐点
              pointColor = '#4297fe'
            }
            let obj = {
              gt: index - 1,
              lte: index,
              color: color     //前部分颜色
            }
            let pointObj = {
              name: '',
              value: '',
              xAxis: item.dateTime,
              yAxis: item.price,
              symbol: 'circle',
              symbolSize: 10,
              itemStyle: {
                color: pointColor,
                borderColor: '#fff'
              }
            }
            pieces.push(obj)
            if (item.continuationPointS || item.turnPoint) {
              markPoint.push(pointObj)
            }
          })
          let { abscissaAxis, max, min } = res.data
          let interval = +((max - min) / 2).toFixed(2)
          let increase = ((max - abscissaAxis) / abscissaAxis * 100).toFixed(2) + '%'
          let decrease = ((min - abscissaAxis) / abscissaAxis * 100).toFixed(2) + '%'
          let dataGather = new Object
          dataGather['xAxis'] = xAxis // x轴数据
          dataGather['series1'] = series1 // 第一条线
          dataGather['series2'] = series2 // 第二条线
          dataGather['first_data'] = res.data // 数据集
          dataGather['splitDate'] = splitDate // 分割线
          dataGather['interval'] = interval // 间隔
          dataGather['increase'] = increase
          dataGather['decrease'] = decrease
          dataGather['pieces'] = pieces // 线条颜色
          dataGather['markPoint'] = markPoint // 标记点
          drawChart(dataGather)
        }
      }).catch((err) => {

      });
    }
    const drawChart = (dataGather) => {
      var chartDom = document.getElementById('chart');
      var myChart = echarts.init(chartDom);
      window.onresize = function () {
        //自适应大小, 不用的话不会自适应大小。
        myChart.resize();
      };
      var option;
      option = {
        title: {
          text: '5日',
          left: '30',
          top: '10',
          subtext: ''
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            align: 'left'
          },
          axisPointer: {
            type: 'cross'
          },
          formatter: (params) => {
            if (params[0].data && params[1].data) {
              return (
                params[0].name +
                "<br/>" +
                params[0].marker +
                params[0].seriesName +
                "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
                "<span style='textAlign:left'>" +
                params[0].data +
                "</span>" +
                "<br>" +
                params[1].marker +
                params[1].seriesName +
                "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
                "<span style='textAlign:left'>" +
                params[1].data +
                "</span>"
              );
            }
          },
        },
        grid: {
          left: '8%',
          right: '4%',
        },
        xAxis: {
          type: 'category',
          symbol: 'none',
          boundaryGap: true,
          data: dataGather.xAxis,
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
          }
        },
        yAxis: [
          {
            name: "",
            type: "value",
            min: dataGather.first_data.min,
            max: dataGather.first_data.max,
            interval: dataGather.interval,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#000",
              },
              formatter: "{value}",
            },
            axisPointer: {
              snap: true
            },
          },
          {
            name: "",
            nameLocation: "start",
            type: "value",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#000",
              },
              formatter: "{value} %",
            },
            axisPointer: {
              snap: true
            },
            splitLine: {
              show: true,
            },
          },
        ],
        visualMap: [{
          show: false,
          dimension: 0,
          seriesIndex: 0,   //第二条曲线
          pieces: dataGather.pieces
        },
        ],
        series: [
          {
            name: '价格',
            type: 'line',
            // yAxisIndex: 0,
            smooth: true,
            symbol: 'none',
            itemStyle: {
              // normal: {
              //   color: "#4297fe",
              //   lineStyle: {
              //     color: "#4297fe",
              //   },
              // },
            },
            data: dataGather.series1,
            markPoint: {
              emphasis: {
                disabled: true // 鼠标经过时不隐藏
              },
              data: dataGather.markPoint
            },
          },
          {
            name: "均价",
            type: "line",
            // yAxisIndex: 1,
            smooth: true,
            symbol: 'none',
            itemStyle: {
              normal: {
                color: "#d17c2e",
                lineStyle: {
                  color: "#d17c2e",
                },
              },
            },
            emphasis: {
              focus: "series",
            },
            data: dataGather.series2,
            markLine: {
              symbol: 'none',
              data: [
                {
                  name: '多单原点',
                  yAxis: dataGather.first_data.longOrderMedium,
                  label: {
                    formatter: '{b}',
                    position: 'insideEndTop',
                    color: '#e56b6d'
                  },
                  lineStyle: {
                    type: 'solid',
                    color: '#a1be9d'
                  }
                },
                {
                  name: dataGather.first_data.longOrderMedium,
                  yAxis: dataGather.first_data.longOrderMedium,
                  label: {
                    formatter: '{b}',
                    position: 'insideEndBottom',
                    color: '#e56b6d'
                  },
                  lineStyle: {
                    type: 'solid',
                    color: '#a1be9d'
                  }
                },
                {
                  name: '空单原点',
                  yAxis: dataGather.first_data.shortOrderMedium,
                  label: {
                    formatter: '{b}',
                    position: 'insideEndTop',
                    color: '#6ac258'
                  },
                  lineStyle: {
                    color: '#a1be9d'
                  }
                },
                {
                  name: dataGather.first_data.shortOrderMedium,
                  yAxis: dataGather.first_data.shortOrderMedium,
                  label: {
                    formatter: '{b}',
                    position: 'insideEndBottom',
                    color: '#6ac258'
                  },
                  lineStyle: {
                    color: '#a1be9d'
                  }
                },
                {
                  name: dataGather.increase,
                  yAxis: dataGather.first_data.max,
                  label: {
                    formatter: '{b}',
                    position: 'insideEndBottom',
                    color: '#db3a3a',
                    fontWeight: 'bold'
                  },
                  lineStyle: {
                    type: 'solid',
                    color: '#a1be9d'
                  }
                },
                {
                  name: '0.00 %',
                  yAxis: dataGather.first_data.abscissaAxis,
                  label: {
                    formatter: '{b}',
                    position: 'insideEndTop',
                    color: '#bab8ba',
                    fontWeight: 'bold'
                  },
                  lineStyle: {
                    color: '#a1be9d'
                  }
                },
                {
                  name: dataGather.decrease,
                  yAxis: dataGather.first_data.min,
                  label: {
                    formatter: '{b}',
                    position: 'insideEndBottom',
                    color: 'red'
                  },
                  lineStyle: {
                    type: 'solid',
                    color: '#a1be9d'
                  }
                },
                {
                  name: '均点',
                  yAxis: dataGather.first_data.avgPoint,
                  label: {
                    formatter: '{b}',
                    position: 'insideEndTop',
                    color: 'red'
                  },
                  lineStyle: {
                    // type: 'solid',
                    color: '#a1be9d'
                  }
                },
                {
                  name: '',
                  xAxis: dataGather.splitDate[0],
                  lineStyle: {
                    type: 'solid',
                    color: '#deddde'
                  }
                },
                {
                  name: '',
                  xAxis: dataGather.splitDate[1],
                  lineStyle: {
                    type: 'solid',
                    color: '#deddde'
                  }
                },
                {
                  name: '',
                  xAxis: dataGather.splitDate[2],
                  lineStyle: {
                    type: 'solid',
                    color: '#deddde'
                  }
                },
                {
                  name: '',
                  xAxis: dataGather.splitDate[3],
                  lineStyle: {
                    type: 'solid',
                    color: '#deddde'
                  }
                },
              ],
              label: {
                distance: [10, 2]
              }
            },
            markArea: {
              silent: true,
              // itemStyle: {
              //   color: 'rgba(255, 173, 177, 0.4)'
              // },
              data: [
                [
                  {
                    name: '',
                    yAxis: dataGather.first_data.shortOrderLow,
                    itemStyle: {
                      //控制当前区域样式
                      color: '#c6e2c2'
                    }
                  },
                  {
                    yAxis: dataGather.first_data.shortOrderHigh
                  }
                ],
                [
                  {
                    name: '',
                    yAxis: dataGather.first_data.longOrderLow,
                    itemStyle: {
                      //控制当前区域样式
                      color: '#facdca'
                    }
                  },
                  {
                    yAxis: dataGather.first_data.longOrderHigh
                  }
                ]
              ]
            }
          },
        ]
      };

      option && myChart.setOption(option);

    }
    //添加续点
    const addContinuePoints = () => {
      if (state.continuePoints.length > 0) {
        let status = state.continuePoints.some(item => {
          return !item.time
        })
        if (status) {
          ElMessage({
            message: "请先选择时间",
            type: "warning",
          });
          return
        }
      }
      if (state.continuePoints.length === 10) {
        ElMessage({
          message: "最多添加十个续点",
          type: "warning",
        });
        return
      }
      state.continuePoints.push({
        time: "",
        key: Date.now(),
      });
      state.newContinuePoints = [...state.continuePoints];
    };
    //删除续点
    const delContinuePoints = (index) => {
      state.continuePoints.splice(index, 1);
    };
    //改变时间
    const changeXdianTime = (value, index) => {
      if (state.continuePoints.length > 1) {
        let newPoints = []
        state.continuePoints.forEach((item, i) => {
          if (index !== i) {
            newPoints.push(item)
          }
        });
        let status = newPoints.some((item, index) => {
          return item.time === value
        });
        if (status) {
          ElMessage({
            message: "请先选择日期或检查是否重复添加",
            type: "warning",
          });
          state.newContinuePoints.splice(index, 1);
          state.continuePoints[index] = {
            time: "",
            key: Date.now(),
          };
        }
      }
    };
    //添加拐点
    const addTurnPoints = () => {
      if (state.turnPoints.length > 0) {
        let status = state.turnPoints.some(item => {
          return !item.time
        })
        if (status) {
          ElMessage({
            message: "请先选择时间",
            type: "warning",
          });
          return
        }
      }
      if (state.turnPoints.length === 10) {
        ElMessage({
          message: "最多添加十个拐点",
          type: "warning",
        });
        return
      }
      state.turnPoints.push({
        time: "",
        key: Date.now(),
      });
      state.newTurnPoints = [...state.turnPoints];
    };
    //删除拐点
    const delTurnPoints = (index) => {
      state.turnPoints.splice(index, 1);
    };
    const changeInflectionPointTime = (value, index) => {
      if (state.turnPoints.length > 1) {
        let newPoints = []
        state.turnPoints.forEach((item, i) => {
          if (index !== i) {
            newPoints.push(item)
          }
        });
        let status = newPoints.some((item, index) => {
          return item.time === value
        });
        // let status = state.newTurnPoints.some((item, index) => {
        //   return (item.time === value && index < state.turnPoints.length - 1);
        // });
        if (status) {
          ElMessage({
            message: "请先选择日期或检查是否重复添加",
            type: "warning",
          });
          state.newTurnPoints.splice(index, 1);
          state.turnPoints[index] = {
            time: "",
            key: Date.now(),
          };
        }
      }
    };
    onMounted(() => {
      getFutureTypeEnum()
    })
    return {
      ...toRefs(state),
      getFutureTypeEnum,
      getPointData,
      drawChart,
      getFutureAllDate,
      getSysRecommend,
      srueEdit,
      change,
      addContinuePoints,
      changeXdianTime,
      delContinuePoints,
      addTurnPoints,
      changeInflectionPointTime,
      delTurnPoints,
      setDisableDate
    }
  }
}
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.title {
  font-weight: bold;
}
.search-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-box {
    display: flex;
    align-items: center;
    &__title {
      width: 130px;
    }
  }
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    margin-left: 20px;
  }
}
.list-container {
  margin-top: 20px;
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    &__title {
      font-weight: bold;
    }
  }
  .content {
    margin-top: 25px;
    // display: flex;
    width: 100%;
    .chart {
      width: 100%;
      background-color: #f7f6f9;
      text-align: right;
      position: relative;
      .markPoint {
        position: absolute;
        top: 10px;
        right: 4%;
        display: flex;
        .markBlock {
          margin-left: 20px;
          position: relative;
          .point {
            position: absolute;
            top: 2px;
            left: -15px;
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 2px solid #fff;
            background-color: #ffb900;
            margin-right: 5px;
          }
          .turn {
            background-color: #4297fe;
          }
        }
      }
      #chart {
        width: 100%;
        height: 400px;
      }
      .el-button {
        margin: 0 10px 10px 0;
        background-color: #38a28a;
        color: #fff;
      }
    }
    .settingValue {
      margin-top: 20px;
      .module {
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        .point {
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-between;
          .block {
            // margin-bottom: 20px;
            // display: flex;
            .title {
              width: 20%;
              // font-size: 16px;
              font-weight: normal;
              // margin-bottom: 20px;
            }
            ::v-deep input::-webkit-outer-spin-button,
            ::v-deep input::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }
            ::v-deep input[type="number"] {
              -moz-appearance: textfield !important;
            }
            ::v-deep .el-input__inner {
              width: 140px;
              height: 30px;
              text-align: center;
            }
          }
        }
        .common {
          width: 33%;
          .head {
            margin-bottom: 30px;
            font-weight: bold;
          }
          .block {
            margin-bottom: 20px;
            display: flex;
            line-height: 30px;
          }
        }
      }
      .sureBtn {
        // width: 86%;
        text-align: right;
        span {
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
          color: #1c4bf5;
          margin-right: 20px;
        }
        .el-button {
          background-color: #38a28a;
          height: 40px;
          padding: 0 20px;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
}
</style>